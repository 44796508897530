<template>
  <div>
    <slot :fileDropListener="eventHandler" />
  </div>
</template>

<script>
export default {
  components: {
  },
  methods: {
    addPrevention: e => {
      e.preventDefault()
      e.stopPropagation()
    },
    nudgeElement: e => {
      const element = e.target
      const classes = element.className.split(' ')
      if (!classes.includes('v-icon')) { return }
      e.target.className = element.className += ' nudge-element'
      setTimeout(() => (element.className = classes.splice(0, classes.length).join(' ')), 400)
    }
  },
  computed: {
    eventHandler: {
      get () {
        const x = {
          drag: e => this.addPrevention(e),
          dragstart: e => this.addPrevention(e),
          dragend: e => this.addPrevention(e),
          dragover: e => this.addPrevention(e),
          dragenter: e => this.addPrevention(e),
          dragleave: e => this.addPrevention(e),
          drop: (e, ...args) => {
            this.addPrevention(e)
            const files = [...e.dataTransfer.files]
            if (files.length < 1) { return }
            this.nudgeElement(e)
            files.forEach(f => (this.$emit('addfile', f)))
          }
        }
        return this.readonly
          ? {}
          : x
      }
    }
  },
  props: {
    readonly: Boolean
  }
}
</script>
